<template id="submenu">
  <b-nav v-if="items.length" :class="!auth.isAdmin() ? 'no-admin' : ''">
    <b-nav-item v-for="(item, index) in items" :key="index" :to="item.to">
      {{ item.content }}
    </b-nav-item>
  </b-nav>
</template>

<script>
import Auth from "@/lib/auth.js";
export default {
  data: function () {
    return {
      items: [],
    };
  },

  methods: {
    update() {
      this.items = [];
      if (this.$route.meta.subMenu) {
        for (let i in this.$router.options.routes) {
          const subroute = this.$router.options.routes[i];

          if (subroute.children) {
            for (let x in subroute.children) {
              const child = subroute.children[x];

              if (
                (child.name == this.$route.name && child.meta.menu) ||
                this.$route.meta.subMenu.indexOf(child.name) > -1
              ) {
                if (
                  child.meta.permesso &&
                  !Auth.isUserEnable(child.meta.permesso)
                ) {
                  continue;
                }
                this.items.push({
                  to: child.path
                    .replace(":id", this.$route.params.id)
                    .replace(":val_id", this.$route.params.val_id),
                  content: child.meta.label,
                  active: child.name == this.$route.name ? true : false,
                });
              }
            }
          }
        }
      }
    },
  },

  created: function () {
    this.update();
  },

  watch: {
    $route: function () {
      this.update();
    },
  },
  computed: {
    auth: function () {
      return Auth;
    },
  },
};
</script>
