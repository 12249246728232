<template>
  <div class="alert-messages">
    <alert
      v-for="(message, index) in messages"
      :message="message"
      :key="index"
    ></alert>
  </div>
</template>

<script>
import Alert from "@/components/Alert.vue";

export default {
  computed: {
    messages: function () {
      return this.$store.state.messages;
    },
  },
  components: {
    Alert,
  },
};
</script>
