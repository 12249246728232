<template id="main">
  <div>
    <header-app />
    <sub-menu />
    <router-view />
  </div>
</template>

<script>
import Auth from "@/lib/auth.js";
import HeaderApp from "@/components/Header.vue";
import SubMenu from "@/components/SubMenu.vue";
export default {
  components: {
    HeaderApp,
    SubMenu,
  },
  computed: {
    auth: function () {
      return Auth;
    },
  },
  mounted() {
    if (
      this.auth.isAdmin() ||
      this.auth.isUserEnable("opta_team_setup") ||
      this.auth.isUserEnable("opta_coach") ||
      this.auth.isUserEnable("geomatch")
    ) {
      document.body.className = "admin";
    } else {
      document.body.className = "no-admin";
    }
  },
};
</script>
