<template id="menu">
  <b-navbar-nav v-if="items.length">
    <b-nav-item v-for="(item, index) in items" :key="index" :to="item.to">
      {{ item.content.toUpperCase() }}
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import Auth from "@/lib/auth.js";
export default {
  data: function () {
    return {
      items: [],
    };
  },

  methods: {
    update() {
      this.items = [];
      for (let i in this.$router.options.routes) {
        const route = this.$router.options.routes[i];
        if (route.children) {
          for (let x in route.children) {
            const child = route.children[x];
            if (
              child.meta &&
              child.meta.module == this.$route.meta.module &&
              child.meta.menu
            ) {
              if (
                child.meta.permesso &&
                !Auth.isUserEnable(child.meta.permesso)
              ) {
                continue;
              }
              var active = child.path == this.$route.path;
              this.items.push({
                to: child.path,
                content: child.meta.label,
                active: active,
              });
            }
          }
        }
      }
    },
  },

  created: function () {
    this.update();
  },

  watch: {
    $route: function () {
      this.update();
    },
  },
};
</script>
