<template>
  <b-alert
    :show="dismissCountDown"
    dismissible
    fade
    :variant="message.status == 1 ? 'success' : 'danger'"
    @dismissed="dismissed"
    @dismiss-count-down="countDownChanged"
  >
    {{ $t(message.msg) }}
  </b-alert>
</template>

<script>
export default {
  data: function () {
    return {
      dismissSecs: 2,
      dismissCountDown: 0,
    };
  },
  props: ["message"],

  created: function () {
    this.dismissCountDown = this.dismissSecs;
  },
  methods: {
    dismissed() {
      this.dismissCountDown = 0;
      this.$store.commit("msgRemove", this.message);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
  watch: {
    dismissCountDown: function () {
      if (this.dismissCountDown == 0) {
        this.$store.commit("msgRemove", this.message);
      }
    },
  },
};
</script>
