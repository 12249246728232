<template>
  <div>
    <alerts></alerts>
    <b-navbar
      toggleable="lg"
      :type="auth.isAdmin() ? 'dark' : 'light'"
      :variant="auth.isAdmin() ? 'dark' : 'light'"
    >
      <!--b-navbar-brand href="#">Dominance</b-navbar-brand-->

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <menu-app />
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              {{ user.nomeCompleto }}
            </template>
            <b-dropdown-item to="/home"
              ><b-icon icon="house-fill" aria-hidden="true" />
              Home</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('players')"
              to="/scouting/players"
              ><b-icon icon="house-fill" aria-hidden="true" />
              Players</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('coaches')"
              to="/scouting/coaches"
              ><b-icon icon="shield-shaded" aria-hidden="true" />
              Coaches</b-dropdown-item
            >
            <b-dropdown-item to="/profile"
              ><b-icon icon="person-fill" aria-hidden="true" />
              Profile</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('admin_users')"
              to="/admin/users"
              ><b-icon icon="people-fill" aria-hidden="true" />
              Users</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('opta_player_setup')"
              to="/admin/opta/setup"
              ><b-icon icon="tools" aria-hidden="true" /> Opta Player
              Setup</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('opta_player_setup')"
              to="/admin/micro/player/setup"
              ><b-icon icon="grid3x3-gap-fill" aria-hidden="true" />
              Micro/Weight Player Setup</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('opta_player_setup')"
              to="/admin/micro/player/basic/skill/setup"
              ><b-icon icon="grid3x3-gap-fill" aria-hidden="true" />
              Micro/Weight Player Basic Skill Setup</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('opta_player_setup') && false"
              to="/admin/role/weight/list/setup"
              ><b-icon icon="grid3x3-gap-fill" aria-hidden="true" /> Role Weight
              List Setup</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('correlation_weight_setup')"
              to="/admin/correlation/weight/setup"
              ><b-icon icon="grid3x3-gap-fill" aria-hidden="true" /> Correlation
              Weight Setup</b-dropdown-item
            >

            <b-dropdown-item
              v-if="auth.isUserEnable('opta_player_setup')"
              to="/admin/player/index/game"
              ><b-icon icon="bar-chart-fill" aria-hidden="true" /> Player Index
              Game
            </b-dropdown-item>
            <b-dropdown-item
              v-if="auth.isUserEnable('opta_player_setup')"
              to="/admin/principles/player/setup"
              ><b-icon icon="tools" aria-hidden="true" /> Principles Player
              Setup</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('opta_team_setup')"
              to="/admin/opta/team/setup"
              ><b-icon icon="tools" aria-hidden="true" /> Opta Coach
              Setup</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('opta_team_setup')"
              to="/admin/principles/coach/setup"
              ><b-icon icon="tools" aria-hidden="true" /> Principles Coach
              Setup</b-dropdown-item
            >
            <b-dropdown-item
              v-if="auth.isUserEnable('opta_player_setup')"
              to="/admin/principles/game/setup"
              ><b-icon icon="gear-fill" aria-hidden="true" /> Principles Game
              Setup</b-dropdown-item
            >
            <b-dropdown-item @click="logout"
              ><b-icon icon="box-arrow-right" aria-hidden="true" />
              Logout</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import Auth from "@/lib/auth.js";
import MenuApp from "@/components/Menu.vue";
import Alerts from "@/components/Alerts.vue";
export default {
  components: {
    MenuApp,
    Alerts,
  },

  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
  },
  methods: {
    logout: function () {
      Auth.logout(this);
    },
  },
};
</script>
